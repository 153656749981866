<template>
  <client-card
    :client="client"
    :dismiss="dismiss"
    :loading="loading"
    :on-submit="onSubmit"
    :key="`$nested-${entityId}-${clientId}`"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'
import { ClientModalMixin } from '../../clients/mixins'
import { ClientCard } from '../../clients/components'

export default {
  name: 'NestedModalClient',
  mixins: [ClientModalMixin],
  components: {
    ClientCard
  },
  props: {
    entityId: { type: [String, Number], required: true }
  },
  data: () => ({
    returnPath: { name: 'boardCard' }
  }),
  methods: {
    ...mapActions(NAMESPACE, ['listDealClients', 'createDealClient']),
    onResult(client) {
      if (this.clientId === 'new') {
        this.createDealClient({ dealId: this.entityId, clientId: client.id }).then(() => {
          this.listDealClients(this.entityId)
        })
      } else {
        return this.listDealClients(this.entityId)
      }
    }
  }
}
</script>
